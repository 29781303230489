import React, {useEffect, useState} from "react";

import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import {SearchContainer} from "../components/search-component/search-container";
import {setIsLoginModalExpanded, setIsMenuModalExpanded} from "../actions/common.actions";
import {useDispatch} from "react-redux";
import {BrandsPage} from "../components/brands-component/brands-page";


const StyledMainPage = styled.div`
  overflow: visible;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 70vh;

  .carousel {

    &-control {
      &-prev {
        display: none;
        margin-left: 100px;

        span {
          border-radius: 50%;
          background-color: ${props => props.theme.colors.primaryOrange};
        }
      }

      &-next {
        display: none;
        margin-right: 100px;

        span {
          border-radius: 50%;
          background-color: ${props => props.theme.colors.primaryOrange};
        }
      }
    }

  }

  .carousel {
    //overflow: visible;
  }

  .carousel-indicators {
    position: relative;
    top: 500px;
    z-index: 10;
    //background: #efefef;
    & [data-bs-target] {
        //background-color: ${props => props.theme.colors.primaryGray};
      background-color: grey;
    }

    button {
      text-indent: 0;
      width: 150px;
      font-weight: 600;


      &:nth-child(1) {
        &:before {
          //bottom: 20px;
          position: relative;
          margin-left: 0;
          display: block;
          //margin-left: 900px;
          content: "Автостёкла";
        }
      }

      &:nth-child(2) {
        &:before {
          //bottom: 20px;
          position: relative;
          margin-left: 0;
          display: block;
          content: "Поиск запчасти";
        }
      }
    }

    .active {
      color: ${props => props.theme.colors.lightOrange};
      background-color: ${props => props.theme.colors.lightOrange};
    }
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-item {

    &-prev {
      background: transparent;
    }

    &-next {
      background: transparent;
    }

    &-start {
      background: transparent;
    }
  }

  .carousel-item.active {
    background: transparent;
  }

  .body-circle {
    z-index: 0;
    position: absolute;
    background-color: white;
    //background-color: #191a1f;
    //background: linear-gradient(0deg, #191b1f 0%, #191b1f 51%, #fdfdfd 100%);
    width: 100vw;
    height: 85vw;
    margin-top: calc(-50vw);
    border-radius: 0 0 100% 100%;
  }

  .left-big-link {
    width: 20vw;
    height: 18vw;
    background: url(/static/png/parts.png) no-repeat;
    transform: scaleX(-1);
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: 1;
    background-size: 100% 100%;

    &:hover {
      span {
        color: ${props => props.theme.colors.lightOrange};
      }
    }

    span {
      position: absolute;
      right: -3vw;
      background: transparent;
      color: #333333;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 4px;
      top: 8vw;
    }
  }

  .right-big-link {
    width: 20vw;
    height: 18vw;
    background: url(/static/png/parts.png) no-repeat;
    position: absolute;
    right: 0;
    bottom: 100px;
    //z-index: ;
    background-size: 100% 100%;

    &:hover {
      span {
        color: ${props => props.theme.colors.lightOrange};
      }
    }

    span {
      color: #333333;
      background: transparent;
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 4px;
      position: absolute;
      left: -4vw;
      top: 10vw;
    }
  }


  @media (max-width: 1500px) {
    .body-circle {
      height: 100vw;
    }
  }

  @media (max-width: 1200px) {
    .body-circle {
      height: 110vw;
    }

    .left-big-link {
      width: 30vw;
      height: 28vw;
    }

    .right-big-link {
      width: 30vw;
      height: 28vw;
    }
  }

  @media (max-width: 1000px) {
    .body-circle {
      height: 120vw;
    }
  }

  @media (max-width: 768px) {
    .body-circle {
      height: 180vw;
      width: 100vw;
    }

    .left-big-link {
      width: 40vw;
      height: 38vw;
    }

    .right-big-link {
      //right: 4vw;
      width: 40vw;
      height: 38vw;
    }

    .carousel {
      height: 200px;
    }
    .carousel-inner {
      height: 200px;
    }
    .carousel-item {
      height: 200px;
    }

    .carousel-indicators {
      display: none;
    }
  }
  @media (max-height: 700px) {
    .body-circle {
      height: 180vw;
      width: 100vw;
    }

    .left-big-link {
      bottom: -50px;
      width: 26vw;
      height: 24vw;
    }

    .right-big-link {
      bottom: -50px;
      width: 26vw;
      height: 24vw;
    }
  }
`

export const MainPage = () => {
    const dispatch = useDispatch();
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    }

    useEffect(() => {
        dispatch(setIsMenuModalExpanded(false));
        dispatch(setIsLoginModalExpanded(false));
    }, [dispatch])

    return (
        <StyledMainPage>
            <div className="body-circle"/>
            <Carousel activeIndex={index} onSelect={handleSelect} interval={null}
                      indicatorLabels={['Поиск по запчастям', 'Автостёкла']}>
                <Carousel.Item>
                    <BrandsPage/>
                    {/*<SearchContainer/>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <SearchContainer/>
                </Carousel.Item>
            </Carousel>
            <span className="left-big-link"/>
            <span className="right-big-link"/>
        </StyledMainPage>
    )
}