import React, {useState} from "react";

import styled from "styled-components";
import {Transition} from "react-transition-group";

import {BrandsContainer} from "./brands-container";
import {SelectedModel} from "./selected-model";
import {SelectedCarBody} from "./selected-car-body";
import {ModelGeneration} from "./selected-model-generation";

const StyledBrandsPage = styled.div`
  background-color: transparent;
  color: #333333;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  height: 435px;

  .main {
    background: transparent;
  }


  .big-title {
    position: relative;
    z-index: 2;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    margin: 0 auto;
    width: 675px;
    background-color: white;
  }

  .main-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 240px;
  }

  .main-container {
    height: 240px;
    width: 1200px;
    display: flex;
    flex-direction: row;
  }

  .logo-expanded {
    z-index: 99999;
    position: absolute;
    display: flex;
    height: 240px;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    //animation: show-logo 0.5s forwards;
  }

  .logo-expanded.entering {
    animation: show-logo .5s forwards;
  }

  .logo-expanded.entered {
    opacity: 1;
    cursor: pointer;
  }

  .logo-expanded.exiting {
    animation: hide-logo .5s forwards;
  }

  .logo-expanded.exited {
  }


  @keyframes show-logo {
    0% {
      opacity: 0;
      margin-left: 200px;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
      margin-left: 0;
    }
  }

  @keyframes hide-logo {
    0% {
      opacity: 1;
      margin-left: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
      margin-left: 200px;
    }
  }

  @media (max-width: 1500px) {
    height: 39vw;
  }

  @media (max-width: 1200px) {
    height: 56vw;
  }

  @media (max-width: 1000px) {
    height: 66vw;
  }

  @media (max-width: 768px) {
    .big-title {
      font-size: 24px !important;
      width: auto !important;
      line-height: 28px !important;
      margin-top: 55px !important;
    }
  }
`
const SpanLogo = styled.span`
  content: "";
  display: inline-block;
  width: 70px;
  height: 70px;
  background: url(/static/${props => props.logopath}) no-repeat;
  background-size: 70px 70px;
  border-radius: 100%;
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
  }
`

export const BrandsPage = () => {

    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedCarBody, setSelectedCarBody] = useState("");
    const [lastSelectedBrand, setLastSelectedBrand] = useState("");

    return (
        <StyledBrandsPage>
            <div className="main">
                <div className="big-title">
                    Оригинальные лобовые стёкла
                </div>
                <div className="main-wrapper">
                    <div className="main-container">
                        <Transition
                            in={selectedBrand ? true : false}
                            timeout={500}
                        >
                            {state =>
                                <div className={`logo-expanded ${state}`} onClick={() => {
                                    setSelectedBrand("");
                                    setSelectedModel("");
                                    setSelectedCarBody("");
                                }}>
                                    <SpanLogo id={selectedBrand} logopath={`webp/${lastSelectedBrand}.webp`}/>
                                </div>}
                        </Transition>
                        {
                            selectedBrand && selectedModel && selectedCarBody && (
                                <ModelGeneration
                                    selectedCarBody={selectedCarBody}
                                />
                            )
                        }
                        {
                            selectedBrand && selectedModel && !selectedCarBody && (
                                <SelectedCarBody
                                    selectedModel={selectedModel}
                                    setSelectedCarBody={setSelectedCarBody}
                                />
                            )
                        }

                        {
                            selectedBrand && !selectedModel && (
                                <SelectedModel
                                    selectBrandFunction={setSelectedModel}
                                    selectedBrand={selectedBrand}
                                />
                            )
                        }
                        {
                            !selectedBrand && !selectedModel && (
                                <BrandsContainer
                                    selectBrandFunction={setSelectedBrand}
                                    selectLastBrandFunction={setLastSelectedBrand}
                                />)
                        }
                    </div>
                </div>
            </div>
        </StyledBrandsPage>
    )
}