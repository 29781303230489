import React from "react";

import styled from "styled-components";
import {SearchField} from "./search-field";

const StyledSearchContainer = styled.div`
  position: relative;
  background-color: transparent;
  color: #333333;
  display: flex;
  flex-direction: column;
  overflow: visible;
  height: auto;
  min-height: 435px;


  .big-title {
    position: relative;
    z-index: 2;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    margin: 0 auto;
    width: 675px;
    //background-color: white;
  }

  @media (max-width: 1500px) {
    height: 56vw;
  }

  @media (max-width: 1200px) {
    height: 56vw;
  }

  @media (max-width: 1000px) {
    height: 66vw;
  }

  @media (max-width: 768px) {
    height: 120vw;

    .big-title {
      font-size: 24px !important;
      width: auto !important;
      line-height: 28px !important;
      margin-top: 55px !important;
    }

    header {
      img {
        height: 60px;
      }
    }

    .main {
      margin-top: 100px;
    }
  }
`

export const SearchContainer = () => {
    return (
        <StyledSearchContainer>
            <div className="main">
                <div className="big-title">
                    Оригинальные автозапчасти и лобовые стекла из Европы
                </div>
                <SearchField/>
            </div>
        </StyledSearchContainer>
    )
}