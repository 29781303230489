import React, {useState} from "react";

import styled from "styled-components";
import {useDispatch} from "react-redux";
import {setIsLoginModalExpanded, setIsMenuModalExpanded} from "../../actions/common.actions";
import NavItem from "../navigator/nav-item";
import api from "../../utils/api";
import {useAuthContext} from "../../providers/auth.provider";
import {setToken} from "../../utils/session";

const StyledLoginPopup = styled.div`
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4) !important;

  .login-modal-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-modal-body {
    border-radius: 20px;
    background-color: white;
    width: 415px;
    height: 415px;
    display: flex;
    flex: inherit;
    flex-direction: column;
  }

  .close-btn-wrapper {
    border-radius: inherit;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }

  .close-btn {
    margin: 30px 25px 20px 0;
    width: 19px;
    height: 18px;
    display: inline-block;
    background: url(/static/svg/close-menu.svg) no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .form-wrapper {
    padding: 15px 80px;
  }

  .form-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }

  .form-links {
    margin: 25px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  a {
    margin-right: 0 !important;

    &:hover {
      border-bottom: none !important;
    }
  }

  .float-left {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .float-right {
    color: ${props => props.theme.colors.primaryOrange};
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
  }

  .login-input-wrapper {
    margin-bottom: 1rem;

    input {
      background: ${props => props.theme.colors.primaryGray};
      border-radius: 28px;
      border: none;
      padding: 0 20px;
      height: 40px;
      width: 100%;
      font-size: 12px;
      font-weight: 400;

      &:hover {
        //background: transparent;
        border: none !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        opacity: 1 !important;
      }

      &:focus {
        //border: 1px solid #ced4da;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        opacity: 1 !important;
        outline: none;
      }

      &:active {
        border: none;
      }
    }
  }

  .password-input-wrapper {
    input {
      background: ${props => props.theme.colors.primaryGray};
      border-radius: 28px;
      border: none;
      padding: 0 20px;
      height: 40px;
      width: 100%;
      font-size: 12px;
      font-weight: 400;

      &:hover {
        //background: transparent;
        border: none !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        opacity: 1 !important;
      }

      &:focus {
        //border: 1px solid #ced4da;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        opacity: 1 !important;
        outline: none;
      }

      &:active {
        border: none;
      }
    }
  }

  .submit-button-wrapper {
    margin-top: 1rem;
  }

  .submit-button {
    background: ${props => props.theme.colors.primaryOrange};
    width: 100%;
    border: none;
    border-radius: 25px;
    color: ${props => props.theme.colors.primaryGray};
    font-size: 14px;
    font-weight: 500;
    padding: 20px 0;
  }

  .login-issue {
    color: red;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    justify-content: center;
  }
`

export const LoginPopup = () => {
    const dispatch = useDispatch();
    const auth = useAuthContext();
    const [loginInput, setLoginInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [loginIssue, setLoginIssue] = useState(false);

    async function onSubmit() {
        try {
            const {data: res} = await api.post('auth/token/login/', {
                'email': loginInput,
                'password': passwordInput
            });
            console.log('login', res);

            if (res.auth_token) {
                setToken(res.auth_token);
                await auth.reloadUser();
            }
        } catch (err) {
            setLoginIssue(true);
            console.log(err);
        }
    }

    console.log("AUTH USER IS", auth.user);
    return (
        <StyledLoginPopup>
            <div className="login-modal-container">
                <div className="login-modal-body">
                    <div className="close-btn-wrapper">
                        <span
                            className="close-btn"
                            onClick={() => {
                                dispatch(setIsLoginModalExpanded(false));
                            }}
                        />
                    </div>
                    <div className="form-wrapper">
                        <div className="login-form">
                            <div className="form-title"> Логин</div>
                            <div className="form-links">
                                <div className="float-left">
                                    <NavItem title="register" to="register" onClick={() => {
                                        dispatch(setIsLoginModalExpanded(false));
                                        dispatch(setIsMenuModalExpanded(false));
                                    }}>
                                        Нет аккаунта?
                                    </NavItem>
                                </div>
                                <div className="float-right">
                                    <NavItem title="register" to="register" onClick={() => {
                                        dispatch(setIsLoginModalExpanded(false));
                                        dispatch(setIsMenuModalExpanded(false));
                                    }}>
                                        Регистрация
                                    </NavItem>
                                </div>
                            </div>
                            <div className="form-block">
                                <div className="login-input-wrapper">
                                    <input
                                        type="text"
                                        id="username"
                                        name="login"
                                        placeholder="Логин"
                                        onChange={(el) => {
                                            setLoginInput(el.target.value);
                                            setLoginIssue(false);
                                        }}
                                    />
                                </div>
                                <div className="password-input-wrapper">
                                    <input
                                        type="password"
                                        id="password"
                                        placeholder="Пароль"
                                        name="password"
                                        maxLength="20"
                                        onChange={(el) => {
                                            setPasswordInput(el.target.value);
                                            setLoginIssue(false);
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                loginIssue && (
                                    <div className="login-issue">
                                        Не удалось войти
                                    </div>
                                )
                            }
                            <div className="submit-button-wrapper">
                                <button className="submit-button" onClick={onSubmit}>
                                    Войти
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledLoginPopup>
    )
}