import React from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";

const StyledDeliveryPage = styled.div`
    .delivery-container {
      //height: 400px;
      display: flex;
      justify-content: center;
    }
  .delivery-wrapper {
    position: relative;
    padding: 60px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    h1 {
      margin-bottom: 30px;
    }
  }
`

export const DeliveryPage = () => {
    return (
        <StyledDeliveryPage>
            <CommonTemplate>

                <div className="delivery-container">
                    <div className="delivery-wrapper">
                        <div className="delivery-text"><h1>Доставка</h1><p>Дорогие клиенты,</p>

                            <p>Мы можем организовать доставку в регионы через СДЭК или Деловыми Линиями.<br/>
                                За дополнительной информацией обращайтесь к своим менеджерам.</p>

                            <p>- Как и все наши клиенты, вы также можете воспользоваться самовывозом:<br/>
                                    <b> Ермакова роща 7б стр.8 </b><br/>
                                    </p>
                            <p> Костерев А.С.&nbsp;<br/>
                                Tel.:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +00 (0) 000 000 000 - 00<br/>
                                Fax:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +00 (0) 000 000 000 - 00<br/>
                                Mobil.:&nbsp; +00 (000) 000 000 0<br/>
                                e-mail:&nbsp;<a href="mailto:Info@alexparts.ru"
                                                target="_blank">Info@alexparts.ru</a><br/>
                                <a href="https://alexparts.ru/" target="_blank">alexparts.ru</a></p>
                        </div>
                    </div>
                </div>
            </CommonTemplate>
        </StyledDeliveryPage>
    )
}